export function downloadFileUsingUrl(url) {
  if(!url) return
  var element = document.getElementById('download-link');
  element.setAttribute('href', url);
  element.setAttribute('target', '_blank');
  element.click();
}

export function donwloadAllfiles(urlsList) {
  if (!Array.isArray(urlsList)) return;

  const urls = urlsList;

  var interval = setInterval(download, 300, urls);

  function download(urls) {
    var url = urls.pop();

    var a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', '');
    a.setAttribute('target', '_blank');
    a.click();

    if (urls.length == 0) {
      clearInterval(interval);
    }
  }
}

const downloadFile = (url) => {
  var element = document.createElement('a');
  element.setAttribute('href', url);

  document.body.appendChild(element);
  element.setAttribute('target', '_blank');
  element.click();
  document.body.removeChild(temporaryDownloadLink);
};

export function download_files(files) {
  function download_next(i) {
    if (i >= files.length) {
      return;
    }
    console.log('index', i);
    var a = document.createElement('a');
    a.href = files[i].download;
    a.target = '_parent';
    // Use a.download if available, it prevents plugins from opening.
    if ('download' in a) {
      a.download = files[i].filename;
    }
    // Add a to the doc for click to work.
    (document.body || document.documentElement).appendChild(a);
    if (a.click) {
      a.click(); // The click method is supported by most browsers.
    } else {
      $(a).click(); // Backup using jquery
    }
    // Delete the temporary link.
    a.parentNode.removeChild(a);
    // Download the next file with a small timeout. The timeout is necessary
    // for IE, which will otherwise only download the first file.
    setTimeout(function() {
      download_next(i + 1);
    }, 1200);
  }
  // Initiate the first download.
  download_next(0);
}
